import React, { createContext, useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { useImmer } from 'use-immer'
import axios from 'axios'

import useAuth from '../hooks/use-auth'

function WorkspaceProvider({ children }) {
  const toast = useToast()

  const { token } = useAuth()

  const [id, setId] = useState(sessionStorage.getItem('BedukaWorkspace') || 1)
  const [loading, setLoading] = useState(true)
  const [workspaces, updateWorkspaces] = useImmer([])

  let baseURL = 'http://localhost:3333'

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    baseURL = 'https://api.vale-bolsa.beduka.com'
  } else if (process.env.REACT_APP_ENVIRONMENT === 'beta') {
    baseURL = 'https://beta.api.vale-bolsa.beduka.com'
  }

  const http = axios.create({
    baseURL
  })

  http.interceptors.request.use(function (config) {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    if (id) {
      config.headers.campaignId = id
    }

    return config
  })

  http.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  async function getWorkspaces() {
    setLoading(true)

    try {
      const {
        data: { data }
      } = await http.get('campaign')

      updateWorkspaces(draft => {
        return draft.concat(data)
      })

      if (!id) {
        setId(data.length > 0 && data[0].id)
      }
    } catch {
      toast({
        title: 'Ops...',
        description: 'Ocorreu um problema ao buscar as campanhas',
        status: 'error',
        duration: 4000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      sessionStorage.setItem('BedukaWorkspace', id)
    }
  }, [id])

  useEffect(() => {
    if (token) getWorkspaces()
  }, [token])

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <WorkspaceContext.Provider
      value={{
        loading,
        http,
        id,
        token,
        setId,
        workspaces,
        updateWorkspaces
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  )
}

export const WorkspaceContext = createContext({})

export default WorkspaceProvider
