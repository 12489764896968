import React, { useState } from 'react'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Button,
  Grid,
  useToast
} from '@chakra-ui/react'
import { createGlobalStyle } from 'styled-components'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import dayjs from 'dayjs'

import useWorkspace from '../hooks/use-workspace'

const GlobaStyle = createGlobalStyle`
  .react-daterange-picker {
    padding: 10px;
    border-radius: 5px;
    background: #EDF2F7;

    .react-daterange-picker__wrapper {
      border: none;
    }
  }
`

function CampaignCreateModal({ isOpen, onClose }) {
  const toast = useToast()

  const { http: api, updateWorkspaces } = useWorkspace()

  const [name, setName] = useState()
  const [range, setRange] = useState([new Date(), new Date()])
  const [resultReleaseDate, setResultReleaseDate] = useState()

  const [loading, setLoading] = useState(false)

  async function create() {
    setLoading(true)

    try {
      const { data } = await api.post('campaign', {
        name,
        startDate: dayjs(range[0]).format('YYYY-MM-DD'),
        endDate: dayjs(range[1]).format('YYYY-MM-DD'),
        resultReleaseDate
      })

      updateWorkspaces(draft => {
        return draft.concat(data)
      })

      onClose()
    } catch (err) {
      toast({
        title: 'Ops...',
        description: err.response.data.errors[0].message,
        status: 'error',
        duration: 4000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  return (
    <Drawer isOpen={isOpen} placement="left" size="lg" onClose={onClose}>
      <GlobaStyle />
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Criar nova campanha</DrawerHeader>

          <DrawerBody>
            <FormControl id="name" mb={4} isRequired>
              <FormLabel>Título da campanha</FormLabel>
              <Input name={name} onChange={e => setName(e.target.value)} />
              <FormHelperText>
                Você pode utilizar esse titulo para identificação.
              </FormHelperText>
            </FormControl>

            <Grid templateColumns="repeat(2, 1fr)" mb={3}>
              <FormControl id="period" isRequired>
                <FormLabel>Período de inscrições</FormLabel>
                <DateRangePicker
                  locale="pt-BR"
                  format="dd/MM/y"
                  onChange={setRange}
                  value={range}
                />
              </FormControl>
              <FormControl id="resultReleaseDate">
                <FormLabel>Data do resultado</FormLabel>
                <Input
                  type="date"
                  value={resultReleaseDate}
                  onChange={e => setResultReleaseDate(e.target.value)}
                />
              </FormControl>
            </Grid>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              color="blue"
              isLoading={loading}
              disabled={loading}
              onClick={create}
            >
              Salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default CampaignCreateModal
