import React, { useState } from 'react'
import {
  Flex,
  Grid,
  FormControl,
  FormLabel,
  Button,
  Heading,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td
} from '@chakra-ui/react'
import Paginate from 'react-paginate'

import useWorkspace from '@/hooks/use-workspace'

import { IMetaResponse, ValueOf } from '@/protocols/CommonProtocol'

import DefaultTemplate from '@/templates/DefaultTemplate'

import Breadcrumb from '@/components/Breadcrumb'
import useDidMount from '@/hooks/useDidMount'
import { convertPriceToPT } from '@/utils/sanitization.util'

type IFilterData = {
  course: string
  shift: string
  modality: string
  page: number
}

const OffersPage = () => {
  const { http: api } = useWorkspace() as any

  const [filterData, setFilterData] = useState<IFilterData>({
    course: '',
    shift: '',
    modality: '',
    page: 1
  })
  const [scholarships, setScholarships] = useState<{
    data: any[]
    meta?: IMetaResponse
  }>({
    data: []
  })

  const handleScholarshipSearch = async (
    newFilterData?: Partial<IFilterData>
  ) => {
    try {
      const response = await api.get('scholarship/filter', {
        params: {
          ...filterData,
          ...newFilterData
        }
      })

      setScholarships(response.data)
    } catch (error: any) {
      console.log(error?.message)
    }
  }

  const handleFilterDataChange = (
    type: keyof IFilterData,
    value: ValueOf<IFilterData>
  ) => {
    setFilterData(currentState => ({
      ...currentState,
      [type]: value
    }))
  }

  const handlePageClick = async (page: number) => {
    const selectedPage = page + 1

    handleFilterDataChange('page', selectedPage)

    await handleScholarshipSearch({ page: selectedPage })
  }

  useDidMount(() => {
    handleScholarshipSearch()
  })

  return (
    <DefaultTemplate page="offers">
      <Breadcrumb
        name="Ofertas"
        links={[{ name: 'Página inicial', url: '/' }]}
        current="Ofertas"
      />

      <Flex
        flexDir="column"
        bg="white"
        boxShadow="xs"
        p={6}
        borderRadius={8}
        mt={6}
        mb={10}
      >
        <Heading size="lg" mb={6}>
          Filtro de busca
        </Heading>
        <Grid templateColumns="repeat(3, 1fr)" gap="20px">
          <FormControl id="ies">
            <FormLabel>Curso</FormLabel>
            <Input
              variant="outline"
              onChange={({ target }) =>
                handleFilterDataChange('course', target.value)
              }
            />
          </FormControl>
          <FormControl id="cities">
            <FormLabel>Turno</FormLabel>
            <Input
              variant="outline"
              onChange={({ target }) =>
                handleFilterDataChange('shift', target.value)
              }
            />
          </FormControl>
          <FormControl id="courses">
            <FormLabel>Modalidade</FormLabel>
            <Input
              variant="outline"
              onChange={({ target }) =>
                handleFilterDataChange('modality', target.value)
              }
            />
          </FormControl>
        </Grid>
        <Button
          onClick={() => handleScholarshipSearch()}
          alignSelf="flex-end"
          width={44}
          mt={4}
          colorScheme="facebook"
        >
          Procurar
        </Button>
      </Flex>

      <Table variant="striped" size="md" mt="6">
        <Thead>
          <Tr>
            <Th>Curso</Th>
            <Th>Turno</Th>
            <Th>Modalidade</Th>
            <Th>Instituição</Th>
            <Th>Preço</Th>
            <Th>Desconto</Th>
          </Tr>
        </Thead>
        <Tbody>
          {scholarships.data.map(scholarship => (
            <Tr key={scholarship.id}>
              <Td textTransform="capitalize">{scholarship.course.name}</Td>
              <Td>{scholarship.shift}</Td>
              <Td>{scholarship.modality}</Td>
              <Td>{scholarship.institution.name}</Td>
              <Td>{convertPriceToPT(scholarship.price)}</Td>
              <Td> {convertPriceToPT(scholarship.discount)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Paginate
        containerClassName={'pagination'}
        activeClassName={'active'}
        pageCount={scholarships?.meta?.last_page || 0}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        previousLabel={'<'}
        nextLabel={'>'}
        onPageChange={({ selected }) => handlePageClick(+selected)}
      />
    </DefaultTemplate>
  )
}

export default OffersPage
