import { createGlobalStyle } from 'styled-components'

const GlobaStyle = createGlobalStyle`
  .react-daterange-picker {
    background: #fff;

    .react-daterange-picker__wrapper {
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      padding: 10px 4px;
    }
  }

  ul {
    list-style: none
  }

  .pagination {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 15px;

    margin: 0;
    padding: 0;

    margin-top: 30px;

    li {
      a {
        padding: 10px;
        background: rgba(0, 0, 0, 0.1);
        color: #000;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        transition: all ease .3s;

        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }

      &.active {
        a {
          background-color: #4299e1;
          color: #fff;
        }
      }
    }
  }
`

export default GlobaStyle
