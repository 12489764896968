import { extendTheme, theme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const overrides = {
  styles: {
    global: props => ({
      body: {
        fontFamily: 'Poppins',
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('white', 'gray.800')(props),
        lineHeight: 'base'
      }
    })
  },
  theme: {
    ...theme,
    shadows: {
      ...theme.shadows,
      outline: 'none'
    }
  },
  components: {
    Input: {
      defaultProps: {
        size: 'lg',
        variant: 'filled',
        focusBorderColor: 'blue.400'
      }
    },
    PinInput: {
      defaultProps: {
        size: 'lg',
        variant: 'filled',
        focusBorderColor: 'blue.400'
      }
    },
    Button: {
      defaultProps: {
        size: 'lg'
      }
    }
  }
}

export default extendTheme(overrides)
