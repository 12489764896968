const colors = {
  palette: {
    primary: '#0B1880',
    secondary: '#12CFD8',

    confirm: '#1AB956',
    error: '#d9534f',
    warning: '#F1C34E',
    info: '#188FFB',

    lilac: '#9b36dd',
    lilac2: '#A39FE6',

    link: '#2030ab',
    background: '#f8f9fa',

    gray: '#b9bfc6',
    pink: '#ee3db6',
    green: '#5FE09A'
  },
  grayScale: {
    1: '#212429',
    2: '#DDE2E5',
    3: '#696f76',
    4: '#495057',
    5: '#969696',
    6: '#ABABAB',
    7: '#BFBFBF',
    8: '#B9BFC6',
    9: '#D0D0D0',
    10: '#F0F0F0',
    11: '#FFFFFF'
  }
}

export default colors
