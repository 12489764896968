import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Heading,
  Text
} from '@chakra-ui/react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import ReportsTable from './ReportsTable'
import useWorkspace from '../hooks/use-workspace'
import Paginate from 'react-paginate'

function ReportsFilters() {
  const { http: api } = useWorkspace()
  const [reports, setReports] = useState([])
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [isConcluded, setIsConcluded] = useState(true)
  const [startDate, setStartDate] = useState(new Date('01/01/2019'))
  const [endDate, setEndDate] = useState(new Date())
  const handlePageClick = e => {
    const selectedPage = e.selected
    setPage(selectedPage + 1)
  }

  const handleChange = e => {
    if (e) {
      setStartDate(e[0])
      setEndDate(e[1])
    }
  }

  const getReports = async () => {
    try {
      const response = await api.get('/reports/users', {
        startDate,
        endDate,
        concluded: isConcluded,
        page
      })

      setReports(response.data.data)
      setLastPage(response.data.meta.last_page)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getReports()
  }, [page, startDate, isConcluded])

  const handleStatusSelect = e => {
    e.target.value === 'concluded'
      ? setIsConcluded(true)
      : setIsConcluded(false)
  }

  return (
    <>
      <Box bg="white" boxShadow="xs" p={6} borderRadius={8} mt={6} mb={10}>
        <Heading size="lg" mb={6}>
          Filtro de busca
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap="10px">
          <FormControl id="person">
            <FormLabel>Status</FormLabel>
            <Select
              onChange={e => handleStatusSelect(e)}
              size="lg"
              variant="outline"
            >
              <option value="concluded">Concluídas</option>
              <option value="notConcluded">Não concluídas</option>
            </Select>
          </FormControl>
          <FormControl id="query">
            <FormLabel>Período</FormLabel>
            <DateRangePicker
              onChange={e => handleChange(e)}
              locale="pt-BR"
              format="dd/MM/y"
              value={[startDate, endDate]}
            />
            <FormHelperText>Selecione um período de busca</FormHelperText>
          </FormControl>
        </Grid>
      </Box>

      {reports.length > 0 ? (
        <>
          <ReportsTable reports={reports} lastPage={lastPage} />
          <Paginate
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageCount={lastPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            previousLabel={'<'}
            nextLabel={'>'}
            onPageChange={handlePageClick}
          />
        </>
      ) : (
        <Text fontSize="xl">
          Nenhuma inscrição encontrada com estes parâmetros de busca
        </Text>
      )}
    </>
  )
}

export default ReportsFilters
