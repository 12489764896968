import React from 'react'
import ReactDOM from 'react-dom'

import Providers from './providers'
import Routes from './routes'
import GlobaStyle from './styles/global'

ReactDOM.render(
  <Providers>
    <GlobaStyle />
    <Routes />
  </Providers>,
  document.getElementById('root')
)
