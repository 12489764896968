import React, { useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  ModalCloseButton,
  Text,
  useToast
} from '@chakra-ui/react'

import { Divider } from '@/components'

import useWorkspace from '../../hooks/use-workspace'

const ResendInstructions = ({
  user
}: {
  user: {
    user_id: number
    email: string
  }
}) => {
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState(null)

  const { http: api } = useWorkspace() as any
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleResendInstructions = async () => {
    setLoading(true)
    try {
      const { data } = await api.put(`user/admin/resend-signup/${user.user_id}`)

      setNewPassword(data.newPassword)

      toast({
        title: 'Email reenviado',
        description: 'Email com instruções foi reenviado',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top-right'
      })
    } catch (error: any) {
      toast({
        title: 'Requisição falhou',
        description: 'Email com instruções não foi enviado',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right'
      })

      console.log(error.message)
    }

    setLoading(false)
  }

  const handleClose = () => {
    setNewPassword(null)

    onClose()
  }

  return (
    <>
      <Button bg="transparent" fontWeight="400" w="100%" onClick={onOpen}>
        Reenviar instruções
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reenviar Email de instruções</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {newPassword ? (
              <Text>
                A nova senha do usuário é: <b>{newPassword}</b>
              </Text>
            ) : (
              <>
                <Text>
                  Deseja reenviar o email de instrução para <b> {user.email}</b>
                  ?
                </Text>
                <Divider size={2} orientation="horizontal" />
                <Text fontSize="12px" textStyle="caption">
                  * A senha do usuário será alterada automaticamente
                </Text>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {newPassword ? (
              <Button colorScheme="gray" onClick={handleClose}>
                Fechar
              </Button>
            ) : (
              <Button
                colorScheme="green"
                onClick={handleResendInstructions}
                isLoading={loading}
                disabled={loading}
              >
                Enviar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ResendInstructions
