import React from 'react'
import {
  Box,
  Img,
  Stack,
  Avatar,
  Tooltip,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'

import { AiOutlineLogout, AiOutlinePlus } from 'react-icons/ai'
import { IoSettingsOutline } from 'react-icons/io5'

import CampaignCreateModal from './CampaignCreateModal'

import useAuth from '../hooks/use-auth'
import useWorkspace from '../hooks/use-workspace'

function Navigator() {
  const { setToken, setUser } = useAuth()
  const { id, setId, workspaces } = useWorkspace()

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <CampaignCreateModal isOpen={isOpen} onClose={onClose} />
      <Box
        d="flex"
        flexDir="column"
        justifyContent="space-between"
        align="center"
        bg="gray.900"
        p="20px 0"
        h="100%"
      >
        <Stack direction="column" align="center" spacing={4}>
          <Img
            src="/logo.svg"
            alt="Beduka - Logo"
            maxWidth="50px"
            mb="20px"
            draggable={false}
          />
          {/* {workspaces.length &&
            workspaces?.map(workspace => (
              <Tooltip
                key={workspace.id}
                shouldWrapChildren
                label={workspace?.name}
                bg="gray.800"
                offset={[0, 15]}
                placement="right"
              >
                <Avatar
                  name={workspace?.name}
                  bg="blue.400"
                  loading="lazy"
                  color="white"
                  cursor="pointer"
                  showBorder={Number(workspace?.id) === Number(id)}
                  onClick={() => setId(workspace?.id)}
                />
              </Tooltip>
            ))} */}

          <Tooltip
            shouldWrapChildren
            label="Criar nova campanha"
            bg="gray.800"
            offset={[0, 15]}
            placement="right"
          >
            <IconButton
              icon={<AiOutlinePlus />}
              bg="gray.800"
              size="lg"
              isRound
              aria-label="Criar loja"
              color="white"
              onClick={onOpen}
              _hover={{
                bg: 'gray.700'
              }}
            />
          </Tooltip>
        </Stack>
        <Stack direction="column" spacing={3}>
          <Tooltip
            shouldWrapChildren
            label="Configurações"
            bg="gray.800"
            offset={[0, 15]}
            placement="right"
          >
            <IconButton
              icon={<IoSettingsOutline />}
              bg="gray.800"
              size="lg"
              isRound
              aria-label="Sair"
              color="white"
              _hover={{
                bg: 'gray.700'
              }}
            />
          </Tooltip>
          <Tooltip
            shouldWrapChildren
            label="Sair da conta"
            bg="red.400"
            offset={[0, 15]}
            placement="right"
          >
            <IconButton
              icon={<AiOutlineLogout />}
              bg="gray.800"
              size="lg"
              isRound
              aria-label="Sair"
              color="white"
              _hover={{
                bg: 'gray.700'
              }}
              onClick={() => {
                setToken(null)
                setUser(null)
              }}
            />
          </Tooltip>
        </Stack>
      </Box>
    </>
  )
}

export default Navigator
