import React, { useState, useEffect } from 'react'
import { Line } from '@ant-design/charts'
import {
  Box,
  Heading,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber
} from '@chakra-ui/react'

import DefaultTemplate from '../templates/DefaultTemplate'

import useWorkspace from '../hooks/use-workspace'

export default function Home() {
  const { http: api, loading, id } = useWorkspace()

  const [data, setData] = useState(null)

  const lineConfig = {
    height: 360,
    smooth: true,
    xField: 'day',
    yField: 'Inscritos',
    xAxis: {
      grid: {
        line: false
      }
    },
    color: '#ED8936',
    point: {
      size: 5,
      shape: 'circle'
    },
    label: {
      style: {
        fill: '#aaa'
      }
    }
  }

  async function getData() {
    setData()

    const { data } = await api.get('/campaign/leads')

    if (data) {
      setData(data)
    }
  }

  useEffect(() => {
    if (!loading) {
      getData()
    }
  }, [loading, id])

  return (
    <DefaultTemplate page="dashboard">
      <StatGroup mb="35px" p="25px" bg="gray.100" borderRadius={15}>
        <Stat>
          <StatLabel>Inscrições Iniciadas</StatLabel>
          <StatNumber>{data ? data.users : '...'}</StatNumber>
        </Stat>

        <Stat>
          <StatLabel>Inscrições Concluídas</StatLabel>
          <StatNumber>{data ? data.subscriptions : '...'}</StatNumber>
        </Stat>
      </StatGroup>
      <Box mb="100px">
        <Heading mb={16}>Resumo</Heading>
        <Box>
          <Line {...lineConfig} loading={!data} data={data ? data.graph : []} />
        </Box>
      </Box>
    </DefaultTemplate>
  )
}
