import { useState } from 'react'
export type ErrorType = {
  message: string
  response: {
    status: string
    data: {
      errors: {
        message: string
        field: string
      }[]
    }
  }
}

export type ValidationType = {
  [key: string]: any
}

const parseError = (error: ErrorType) => {
  // eslint-disable-next-line
  const validationMessages = error?.response?.data?.errors
  const errorMessage = error?.message
  const errorStatus = error?.response?.status

  return {
    validationMessages,
    errorMessage,
    errorStatus: +errorStatus
  }
}

const useValidation = () => {
  const [validation, setValidation] = useState<ValidationType>({})

  // eslint-disable-next-line
  const triggerUndeterminedValidation = (error: ErrorType) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line
    }
  }

  const triggerValidation = (error: any) => {
    const data = parseError(error as ErrorType)

    if (!data.validationMessages) {
      triggerUndeterminedValidation(error)
    } else {
      const validationMessages = data.validationMessages
      const newValidation = {} as { [key: string]: string }
      validationMessages.forEach(item => {
        newValidation[item.field] = item.message
      })
      setValidation(lastValidation => ({
        ...lastValidation,
        ...newValidation
      }))
    }
  }

  const addValidation = (newValidation: ValidationType) => {
    setValidation(lastValidation => ({
      ...lastValidation,
      ...newValidation
    }))
  }

  const clearValidation = (key: string) => {
    if (key in validation) {
      setValidation(lastValidation => {
        const updatedData: any = { ...lastValidation }

        delete updatedData[key]

        return updatedData
      })
    }
  }

  const clearAllValidations = () => {
    setValidation({})
  }

  return {
    triggerUndeterminedValidation,
    triggerValidation,
    addValidation,
    clearValidation,
    clearAllValidations,
    parseError,
    validation
  }
}

export default useValidation
