import React, { useState, forwardRef, useEffect, useCallback } from 'react'

import DefaultTemplate from '../templates/DefaultTemplate'
import Breadcrumb from '../components/Breadcrumb'
import { Box, Text, Flex, Button, Tooltip, useToast } from '@chakra-ui/react'
import DatePicker, { registerLocale } from 'react-datepicker'

import useWorkspace from '../hooks/use-workspace'
import br from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('pt-br', br)
const ExamDates = () => {
  const toast = useToast()
  const { http: api } = useWorkspace()
  const [firstDay, setFirstDay] = useState(new Date())
  const [firstDayStartHour, setFirstDayStartHour] = useState(new Date())
  const [secondDay, setSecondDay] = useState(new Date())
  const [secondDayStartHour, setSecondDayStartHour] = useState(new Date())
  const [latestExam, setLatestExam] = useState({})

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button colorScheme="facebook" onClick={onClick} ref={ref}>
      {value}
    </Button>
  ))

  const combineDateWithTime = (date, time) => {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    )
  }

  const dateFormatter = dateToFormat => {
    const date = new Date(dateToFormat)
    return date.toLocaleString('pt-BR')
  }

  const handleCreateExamDate = async () => {
    const dataToSave = {
      firstDate: combineDateWithTime(firstDay, firstDayStartHour),
      secondDate: combineDateWithTime(secondDay, secondDayStartHour)
    }

    try {
      await api.post('/user/admin/examdate', dataToSave)
      toast({
        title: 'Sucesso',
        description: 'Data das provas atualizadas com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  const getExamDate = useCallback(async () => {
    try {
      const response = await api.get('examdate')
      setLatestExam(response.data[0])
      console.log(response.data)
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    getExamDate()
    console.log('render')
  }, [])

  const setEndTime = time => {
    const finalHour = new Date(time)

    return {
      finalHour: finalHour.getHours() + 5,
      finalMinute: finalHour.getMinutes()
    }
  }
  return (
    <DefaultTemplate page="examdates">
      <Breadcrumb
        name="Data das provas"
        links={[{ name: 'Página inicial', url: '/' }]}
        current="Data das provas"
      />
      <Box>
        <Text mt={4} fontSize="2xl">
          Defina quando será a prova.
        </Text>
        <Flex
          borderRadius="md"
          borderWidth="medium"
          mt={4}
          width="100%"
          justifyContent="space-evenly"
          shadow="md"
          height="xm"
        >
          <Box pt={22} borderRightWidth={2} borderRightColor="gray.400">
            <Text fontSize="xl">Primeiro dia:</Text>
            <Text mt={2}>Data do primeiro exame</Text>
            <DatePicker
              selected={firstDay}
              onChange={date => setFirstDay(date)}
              customInput={<CustomInput />}
              locale="pt-br"
              dateFormat="dd/MM/yyy"
            />
            <Text mt={2}>Hora do primeiro exame</Text>

            <Box mt={2}>
              <Tooltip
                label="Clique para definir o horário de inicio"
                aria-label="Um tooltip"
              >
                <Flex>
                  <Text>Inicio: </Text>
                  <Box _hover={{ cursor: 'pointer' }}>
                    <DatePicker
                      selected={firstDayStartHour}
                      onChange={date => setFirstDayStartHour(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      locale="pt-br"
                      timeIntervals={30}
                      timeCaption="Início"
                      dateFormat="HH:mm"
                    />
                  </Box>
                </Flex>
              </Tooltip>
              <Flex mt={2}>
                <Text>Fim: {setEndTime(firstDayStartHour).finalHour}h</Text>
              </Flex>
            </Box>
          </Box>
          <Box pt={22}>
            <Text fontSize="xl">Segundo dia:</Text>
            <Text mt={2}>Data do segundo exame</Text>
            <DatePicker
              selected={secondDay}
              onChange={date => setSecondDay(date)}
              customInput={<CustomInput />}
              locale="pt-br"
              dateFormat="dd/MM/yyy"
            />
            <Text mt={2}>Hora do segundo exame</Text>
            <Box mt={2}>
              <Tooltip
                label="Clique para setar o horário de inicio"
                aria-label="Um tooltip"
              >
                <Flex>
                  <Text>Inicio: </Text>
                  <Box _hover={{ cursor: 'pointer' }}>
                    <DatePicker
                      selected={secondDayStartHour}
                      onChange={date => setSecondDayStartHour(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      locale="pt-br"
                      timeIntervals={30}
                      timeCaption="Início"
                      dateFormat="HH:mm"
                    />
                  </Box>
                </Flex>
              </Tooltip>
              <Flex>
                <Text mb={4} mt={2}>
                  Fim: {setEndTime(secondDayStartHour).finalHour}h
                </Text>
              </Flex>
            </Box>
          </Box>
        </Flex>
        <Flex mt={4} justifyContent="flex-end" width="100%">
          <Button onClick={handleCreateExamDate} colorScheme="whatsapp">
            Atualizar data das provas
          </Button>
        </Flex>
      </Box>
      {latestExam && (
        <Flex
          borderWidth={1}
          borderColor="gray.400"
          borderRadius="md"
          shadow="md"
          mt={4}
          flexDir="column"
          p={4}
        >
          <Text textAlign="center" fontSize="xl">
            Data da prova mais atual
          </Text>
          <Text>
            Primeira prova:{' '}
            <strong>{dateFormatter(latestExam.first_date)} </strong>
          </Text>
          <Text mt={4}>
            Segunda prova:{' '}
            <strong>{dateFormatter(latestExam.second_date)} </strong>
          </Text>
        </Flex>
      )}
    </DefaultTemplate>
  )
}

export default ExamDates
