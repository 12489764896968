import React, { useState, useCallback } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
  ModalCloseButton,
  useToast,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
  Td,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Text,
  PopoverCloseButton,
  PopoverFooter,
  Flex
} from '@chakra-ui/react'
import { FcCancel } from 'react-icons/fc'

import useWorkspace from '@/hooks/use-workspace'

import useDidMount from '@/hooks/useDidMount'
import { IUserData } from '@/protocols/User.protocol'
import { ISubscriptionData } from '@/protocols/Subscription.protocol'

type UserSubscriptionsProps = {
  user: IUserData
  onUpdate: () => Promise<void>
}

const UserSubsCriptions: React.FC<UserSubscriptionsProps> = props => {
  const { user, onUpdate } = props

  const [loading, setLoading] = useState<boolean>(false)
  const [subscriptions, setSubscriptions] = useState<ISubscriptionData[]>([])
  const { http: api } = useWorkspace() as any
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = () => {
    setSubscriptions([])

    onClose()
  }

  const getUserSubs = useCallback(async () => {
    try {
      const response = await api.get(`user/admin/usersubs/${user.user_id}`)

      setSubscriptions(response.data.subscriptions)
    } catch (error) {
      toast({
        title: 'Erro ao buscar inscrições',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }, [])

  const handleCancelSubs = async (subID: number) => {
    setLoading(true)
    try {
      await api.delete(`user/admin/subscription/${subID}`)

      toast({
        title: 'Inscrição cancelada',
        description: 'Inscrição deletada com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'bottom-right'
      })

      await onUpdate()

      handleClose()
    } catch (error) {
      toast({
        title: 'Erro ao cancelar a inscrição',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'bottom-right'
      })
    }

    setLoading(false)
  }

  useDidMount(() => {
    getUserSubs()
  })
  return (
    <>
      <Button bg="transparent" fontWeight="400" w="100%" onClick={onOpen}>
        Inscrições
      </Button>
      <Modal size="4xl" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inscrições do usuário</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {subscriptions.length > 0 ? (
              <Table variant="striped" size="sm" mt="6" mb={8}>
                <Thead>
                  <Tr>
                    <Th>Instituição</Th>
                    <Th>Campus</Th>
                    <Th>Curso</Th>
                    <Th>Turno</Th>
                    <Th>Cancelar</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {subscriptions &&
                    subscriptions.map(subscription => (
                      <Tr key={subscription.id}>
                        <Td>{subscription.scholarship.institution.name}</Td>
                        <Td>{subscription.scholarship.camp.name}</Td>
                        <Td>{subscription.scholarship.course.name}</Td>
                        <Td>{subscription.scholarship.shift}</Td>
                        <Td>
                          <Popover>
                            <PopoverTrigger>
                              <Button bg="transparent">
                                <FcCancel size={22} />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverHeader p={4}>
                                <Text fontSize="xl">Cancelar inscrição</Text>
                              </PopoverHeader>
                              <PopoverCloseButton />
                              <PopoverBody p={4}>
                                <Text fontSize="xl">
                                  Você tem certeza que quer cancelar esta
                                  inscrição?
                                </Text>
                              </PopoverBody>
                              <PopoverFooter
                                d="flex"
                                alignItems="center"
                                justifyContent="center"
                                pb={4}
                              >
                                <Button
                                  onClick={() =>
                                    handleCancelSubs(subscription.id)
                                  }
                                  colorScheme="orange"
                                  isLoading={loading}
                                  isDisabled={loading}
                                >
                                  Cancelar inscrição
                                </Button>
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            ) : (
              <Flex mb={10} justifyContent="center" alignItems="center">
                <Text fontWeight={600} fontSize="xl">
                  Usuário não possui inscrições
                </Text>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserSubsCriptions
