import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import PrivateRoute from './components/PrivateRoute'

import Home from './pages'
import Emissions from './pages/emissions'
import Institutions from './pages/institutions'
import Login from './pages/login'
import Offers from './pages/offers'
import Reports from './pages/reports'
import Users from './pages/users'
import Admins from './pages/admins'
import ExamDates from './pages/examdates'

function Routes() {
  return (
    <Router basename="/">
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/reports" component={Reports} />
        <PrivateRoute exact path="/institutions" component={Institutions} />
        <PrivateRoute exact path="/offers" component={Offers} />
        <PrivateRoute exact path="/emissions" component={Emissions} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/admins" component={Admins} />
        <PrivateRoute exact path="/examdates" component={ExamDates} />
        <Route exact path="/login" component={Login} />
      </Switch>
    </Router>
  )
}

export default Routes
