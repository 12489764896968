import React, { createContext, useState, useEffect } from 'react'

// TODO: CORRECT LOGIN BUG

export const AuthContext = createContext({})

function AuthProvider({ children }) {
  const [user, setUser] = useState()
  const [token, setToken] = useState(localStorage.getItem('BedukaAdminToken'))

  useEffect(() => {
    if (token) {
      localStorage.setItem('BedukaAdminToken', token)
    }
  }, [token])

  useEffect(() => {
    const session = localStorage.getItem('BedukaAdminUser')

    if (session) {
      setUser(localStorage.getItem('BedukaAdminUser'))
    }
  }, [])

  useEffect(() => {
    if (user) {
      localStorage.setItem('BedukaAdminUser', user)
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
