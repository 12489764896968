import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  Heading,
  Flex,
  Text
} from '@chakra-ui/react'
import Select from 'react-select'
import useWorkspace from '../hooks/use-workspace'
import Paginate from 'react-paginate'
import InstitutionsTable from './InstitutionsTable'

function InstitutionsFilter() {
  const { http: api } = useWorkspace()
  const [institutions, setInstitutions] = useState([])
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [names, setNames] = useState([])
  const [city, setCity] = useState('')
  const [cities, setCities] = useState([])
  const [course, setCourse] = useState('')
  const [courses, setCourses] = useState([])

  const getFilterParameters = async () => {
    try {
      const response = await api.get('institution/parameters')

      // This setup is required to make react-select work.
      // React select needs an array with a object containing 'value' and 'label'
      setNames(
        response.data.institutions.map(institution => {
          return {
            value: institution.name,
            label: institution.name
          }
        })
      )

      setCities(
        response.data.cities.map(city => {
          return {
            value: city.city,
            label: city.city
          }
        })
      )

      setCourses(
        response.data.courses.map(course => {
          return {
            value: course.name,
            label: course.name
          }
        })
      )
    } catch (error) {
      console.log(error.message)
    }
  }

  const getInstitutions = async () => {
    setLoading(true)
    try {
      const response = await api.get(
        `institution/filter?name=${name}&city=${city}&course=${course}&page=${page}`
      )
      setInstitutions(response.data.data)
      setLastPage(response.data.meta.last_page)

      setLoading(false)
    } catch (error) {
      console.log(error.message)
      setLoading(false)
    }
  }

  const handlePageClick = e => {
    const selectedPage = e.selected
    setPage(selectedPage + 1)
  }

  useEffect(() => {
    getFilterParameters()
  }, [])

  useEffect(() => {
    getInstitutions()
  }, [page, name, city, course])

  return (
    <>
      <Box>
        <Box bg="white" boxShadow="xs" p={6} borderRadius={8} mt={6} mb={10}>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={6}
          >
            <Heading size="lg">Filtro de busca</Heading>
          </Flex>
          <Grid templateColumns="repeat(3, 1fr)" gap="20px">
            <FormControl id="ies">
              <FormLabel>IES</FormLabel>
              <Select
                onChange={e => setName(e ? e.value : '')}
                placeholder="Selecione uma IES"
                options={names}
                isClearable
              />
            </FormControl>
            <FormControl id="cities">
              <FormLabel>Cidade</FormLabel>
              <Select
                onChange={e => setCity(e ? e.value : '')}
                placeholder="Selecione uma cidade"
                options={cities}
                isClearable
              />
            </FormControl>
            <FormControl id="courses">
              <FormLabel>Curso</FormLabel>
              <Select
                onChange={e => setCourse(e ? e.value : '')}
                placeholder="Selecione um curso"
                options={courses}
                isClearable
              />
            </FormControl>
          </Grid>
        </Box>

        {institutions.length > 0 ? (
          <>
            <InstitutionsTable data={institutions} lastPage={lastPage} />
            <Paginate
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={lastPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              previousLabel={'<'}
              nextLabel={'>'}
              onPageChange={handlePageClick}
            />
          </>
        ) : (
          <Text fontSize="xl">
            Nenhuma IES encontrada com estes parâmetros de busca
          </Text>
        )}
      </Box>
    </>
  )
}

export default InstitutionsFilter
