import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import useAuth from '../hooks/use-auth'

function PrivateRoute({ guard, component: Component, ...rest }) {
  const { token } = useAuth()

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
