import React from 'react'
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'
import { dateFormatter } from '../utils/date.util'
// TODO: make details modal on subscriptions

const ReportsTable = ({ reports }) => {
  return (
    <Table variant="simple" size="sm" mt="6">
      <Thead>
        <Tr>
          <Th>Nome</Th>
          <Th>Sobrenome</Th>
          <Th>Telefone</Th>
          {reports[0].subscriptions.length > 0 && (
            <>
              <Th>Instituição</Th>
              <Th>Curso</Th>
              <Th>Data da inscrição</Th>
            </>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {reports.map(report => (
          <Tr key={report.id}>
            <Td>{report.profile.first_name}</Td>
            <Td>{report.profile.last_name}</Td>
            <Td>{report.profile.phone}</Td>
            {report.subscriptions.length > 0 && (
              <>
                <Td>{report.subscriptions[0].scholarship.institution.name}</Td>
                <Td>{report.subscriptions[0].scholarship.course.name}</Td>
                <Td>{dateFormatter(report.subscriptions[0].created_at)}</Td>
              </>
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default ReportsTable
