import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Avatar,
  Heading,
  List,
  ListItem,
  ListIcon,
  Link as ChakraLink,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  FormControl,
  FormLabel,
  Switch,
  useDisclosure
} from '@chakra-ui/react'
import { DateRangePicker } from 'react-date-range'
import { ptBR } from 'date-fns/locale'
import {
  AiOutlineHome,
  AiOutlineContainer,
  AiOutlineUser,
  AiOutlineKey,
  AiOutlineBook
} from 'react-icons/ai'
import { GrOrganization, GrDocumentPdf } from 'react-icons/gr'
import { MdLocalOffer } from 'react-icons/md'

import { Header } from '../styles/components/Navbar'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import useWorkspace from '../hooks/use-workspace'
import useAuth from '../hooks/use-auth'

function Navbar({ page }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { http: api } = useWorkspace()
  const { token } = useAuth()

  const [loading, setLoading] = useState(false)

  const [ies, setIes] = useState([])

  const [id, setId] = useState()
  const [checked, setChecked] = useState(false)
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  })

  useEffect(async () => {
    const result = await api.get('/institution?page=all').catch(() => {
      alert('Aconteceu algum problema')
    })

    if (result) {
      setIes(result.data)
    }
  }, [])

  async function download() {
    setLoading(true)

    const anchor = document.createElement('a')
    document.body.appendChild(anchor)

    let baseURL = 'http://localhost:3333'

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      baseURL = 'https://api.vale-bolsa.beduka.com'
    } else if (process.env.REACT_APP_ENVIRONMENT === 'beta') {
      baseURL = 'https://beta.api.vale-bolsa.beduka.com'
    }

    const file = baseURL + '/subscription/export/1'

    const headers = new Headers()
    headers.append('ies', id)

    if (checked) {
      headers.append(
        'range',
        JSON.stringify([dateRange.startDate, dateRange.endDate])
      )
    }

    headers.append('Authorization', 'Bearer ' + token)

    await fetch(file, { headers })
      .then(response => response.blob())
      .then(blobby => {
        const objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = 'vale-bolsa.xlsx'
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
      })

    setLoading(false)
  }

  return (
    <Box
      d="flex"
      flexDir="column"
      align="center"
      justifyContent="flex-start"
      bg="gray.50"
      p="40px 25px"
    >
      <Header>
        <Avatar name="root" bg="gray.100" color="blue.400" src="" size="xl" />
        <Heading size="sm" fontWeight="semibold" mt="25px">
          Bem-vindo de volta,
        </Heading>
        <Heading size="lg" color="black" mb={9} isTruncated>
          root
        </Heading>
      </Header>
      <Box mt={9} w="100%">
        <List spacing={5} p="0 10px">
          <ListItem w="100%" fontSize={'14px'} fontWeight="600" align="left">
            <Link to="/">
              <ChakraLink color="black">
                <ListIcon
                  fontSize={22}
                  as={AiOutlineHome}
                  color={page === 'dashboard' ? 'blue.400' : 'black'}
                />
                Dashboard
              </ChakraLink>
            </Link>
          </ListItem>
          <ListItem w="100%" fontSize={'14px'} fontWeight="600" align="left">
            <Link to="/reports">
              <ChakraLink color="black">
                <ListIcon
                  fontSize={22}
                  as={AiOutlineContainer}
                  color={page === 'reports' ? 'blue.400' : 'black'}
                />
                Relatórios
              </ChakraLink>
            </Link>
          </ListItem>
          <ListItem w="100%" fontSize={'14px'} fontWeight="600" align="left">
            <Link to="/institutions">
              <ChakraLink color="black">
                <ListIcon
                  fontSize={22}
                  as={GrOrganization}
                  color={page === 'institutions' ? 'blue.400' : 'black'}
                />
                Instituições
              </ChakraLink>
            </Link>
          </ListItem>
          <ListItem w="100%" fontSize={'14px'} fontWeight="600" align="left">
            <Link to="/users">
              <ChakraLink color="black">
                <ListIcon
                  fontSize={22}
                  as={AiOutlineUser}
                  color={page === 'users' ? 'blue.400' : 'black'}
                />
                Usuários
              </ChakraLink>
            </Link>
          </ListItem>

          <ListItem w="100%" fontSize={'14px'} fontWeight="600" align="left">
            <Link to="/admins">
              <ChakraLink color="black">
                <ListIcon
                  fontSize={22}
                  as={AiOutlineKey}
                  color={page === 'admins' ? 'blue.400' : 'black'}
                />
                Admins
              </ChakraLink>
            </Link>
          </ListItem>

          <ListItem w="100%" fontSize={'14px'} fontWeight="600" align="left">
            <Link to="/examdates">
              <ChakraLink color="black">
                <ListIcon
                  fontSize={22}
                  as={AiOutlineBook}
                  color={page === 'examdates' ? 'blue.400' : 'black'}
                />
                Datas de provas
              </ChakraLink>
            </Link>
          </ListItem>

          <ListItem w="100%" fontSize={'14px'} fontWeight="600" align="left">
            <Link to="/offers">
              <ChakraLink color="black">
                <ListIcon
                  fontSize={22}
                  as={MdLocalOffer}
                  color={page === 'offers' ? 'blue.400' : 'black'}
                />
                Ofertas
              </ChakraLink>
            </Link>
          </ListItem>

          <ListItem w="100%" fontSize={'14px'} fontWeight="600" align="left">
            <Link to="/emissions">
              <ChakraLink color="black">
                <ListIcon
                  fontSize={22}
                  as={GrDocumentPdf}
                  color={page === 'emissions' ? 'blue.400' : 'black'}
                />
                Emissões
              </ChakraLink>
            </Link>
          </ListItem>
        </List>
      </Box>
      <Button
        mt="auto"
        bg="gray.600"
        color="white"
        _hover={{ bg: 'gray.800' }}
        fontSize={14}
        onClick={onOpen}
      >
        Exportar dados
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filtrar exportação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Selecione uma IES"
              value={id}
              onChange={e => setId(e.target.value)}
            >
              <option value="ALL">Todas</option>
              {ies.map(el => (
                <option value={el.id} key={el.id}>
                  {el.name}
                </option>
              ))}
            </Select>
            <br />
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="date-range" mb="0">
                Deseja filtrar por período?
              </FormLabel>
              <Switch
                id="date-range"
                onChange={() => {
                  setChecked(!checked)
                }}
              />
            </FormControl>
            <br />
            {checked && (
              <DateRangePicker
                locale={ptBR}
                ranges={[dateRange]}
                onChange={ranges => {
                  setDateRange(ranges.selection)
                }}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Fechar
            </Button>
            <Button variant="secondary" isLoading={loading} onClick={download}>
              Filtrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Navbar
