import React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  ModalCloseButton,
  Divider
} from '@chakra-ui/react'

const ReportsChanges = ({ changes }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const parsedChanges = JSON.parse(changes)

  return (
    <>
      <Button bg="transparent" size="xs" fontWeight="400" onClick={onOpen}>
        Alterações
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alterações realizadas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Nome: {parsedChanges.firstName}</p>
            <p>Sobrenome: {parsedChanges.lastName}</p>
            <p>CPF: {parsedChanges.cpf}</p>
            <p>Telefone: {parsedChanges.phone}</p>
            <p>email: {parsedChanges.email}</p>
            <Divider mt={4} />
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ReportsChanges
