import React from 'react'

import { Grid, Box } from '@chakra-ui/react'

import Navigator from '../components/Navigator'
import Navbar from '../components/Navbar'

function DefaultTemplate({ children, page, inEditor = false }) {
  return (
    <main>
      <Grid w="100%" h="100vh" templateColumns={'80px 280px auto'}>
        <Navigator />
        <Navbar page={page} />
        <Box p={inEditor ? '0' : '40px'} overflowY="auto">
          {children}
        </Box>
      </Grid>
    </main>
  )
}

export default DefaultTemplate
