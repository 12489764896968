import React from 'react'
import { CircularProgress, Flex, Fade } from '@chakra-ui/react'
import colors from '@/styles/colors'
type LoadingProps = {
  customLoadingElement?: React.ReactNode
  loading: boolean
}

const Loading: React.FC<LoadingProps> = props => {
  const { children, loading, customLoadingElement } = props

  let component

  if (loading) {
    if (customLoadingElement) {
      component = customLoadingElement
    } else {
      component = (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress isIndeterminate color={colors.palette.primary} />
        </Flex>
      )
    }
  } else {
    component = <Fade in={!loading}>{children as React.ReactElement}</Fade>
  }

  return component as React.ReactElement
}

export default Loading
