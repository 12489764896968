import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
const InstitutionsTable = ({ data }) => {
  return (
    <Table variant="simple" size="sm" mt="6">
      <Thead>
        <Tr>
          <Th>Logo</Th>
          <Th>Nome</Th>
          <Th>Acrônimo</Th>
          <Th>Quantidade de bolsas</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map(institution => (
          <Tr key={institution.id}>
            <Td>
              <img src={institution.logo} alt="logo da instituição"></img>
            </Td>
            <Td>{institution.name}</Td>
            <Td>{institution.acronym}</Td>
            <Td>{institution.scholarships.length}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default InstitutionsTable
