import React, { useState } from 'react'
import DefaultTemplate from '../templates/DefaultTemplate'
import Breadcrumb from '../components/Breadcrumb'
import { Flex, Button } from '@chakra-ui/react'
import ReportsFilter from '../components/ReportsFilter'
import ReportGraphics from '../components/ReportsGraphics'

export default function Reports() {
  const [activeReport, setActiveReport] = useState('graphics')

  const handleActiveReport = type => {
    setActiveReport(type)
  }
  return (
    <DefaultTemplate page="reports">
      <Breadcrumb
        name="Relatórios"
        links={[{ name: 'Página inicial', url: '/' }]}
        current="Relatórios"
      />
      <Flex w="100%" mt={4}>
        <Button
          onClick={() => handleActiveReport('graphics')}
          colorScheme="facebook"
          variant={activeReport === 'graphics' ? 'solid' : 'outline'}
        >
          Gráficos
        </Button>
        <Button
          onClick={() => handleActiveReport('subscriptions')}
          ml={2}
          colorScheme="facebook"
          variant={activeReport === 'subscriptions' ? 'solid' : 'outline'}
        >
          Inscrições
        </Button>
      </Flex>
      {activeReport === 'graphics' ? <ReportGraphics /> : <ReportsFilter />}
    </DefaultTemplate>
  )
}
