import React from 'react'

import DefaultTemplate from '../templates/DefaultTemplate'

// import useWorkspace from '../hooks/use-workspace'
import Breadcrumb from '../components/Breadcrumb'
import InstitutionsFilter from '../components/InstitutionsFilter'
import * as S from '../styles/pages/institutions'
export default function Institutions() {
  // const { http: api, loading, id } = useWorkspace()

  return (
    <S.Wrapper>
      <DefaultTemplate page="institutions">
        <Breadcrumb
          name="Instituições"
          links={[{ name: 'Página inicial', url: '/' }]}
          current="Instituições"
        />
        <InstitutionsFilter />
      </DefaultTemplate>
    </S.Wrapper>
  )
}
