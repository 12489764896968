import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Stack,
  Button,
  IconButton,
  Box,
  Img,
  Heading,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast
} from '@chakra-ui/react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import AuthTemplate from '../templates/AuthTemplate'

import useWorkspace from '../hooks/use-workspace'
import useAuth from '../hooks/use-auth'

function Login() {
  const history = useHistory()
  const toast = useToast()

  const { http: api } = useWorkspace()
  const { setToken, setUser } = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [loading, setLoading] = useState(false)
  const [hiddenPassword, setHiddenPassword] = useState(true)

  async function submit() {
    if (!email || !password) {
      return toast({
        title: 'Informe todos os dados',
        description: 'É preciso informar e-mail e senha para prosseguir',
        status: 'info',
        duration: 4000,
        isClosable: true
      })
    }

    setLoading(true)

    try {
      const {
        data: { user, session }
      } = await api.post('user/admin/session', { email, password })

      console.log(user, session, ':::')

      setUser(JSON.stringify(user))
      setToken(session.token)

      history.push('/')
    } catch {
      toast({
        title: 'Ops...',
        description: 'E-mail ou senha inválidos',
        status: 'error',
        duration: 4000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  return (
    <AuthTemplate
      title="Log in"
      subtitle="Bem-vindo de volta!"
      message="É hora de ver como estão as campanhas"
    >
      <Stack
        w="100%"
        d="flex"
        flexDir="row"
        alignItems="left"
        justifyContent="left"
      >
        <Img src="/logo.svg" alt="Beduka - Logo" h={['25px', '25px', '40px']} />
      </Stack>
      <Box>
        <Heading
          as="h1"
          maxWidth={['100%', '100%', '460px']}
          size="2xl"
          color="gray.700"
          m="0 0 45px 0"
        >
          Bem-vindo de volta, faça login
        </Heading>
        <FormControl id="email" mb="20px">
          <FormLabel>Informe seu e-mail</FormLabel>
          <Input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
        </FormControl>
        <FormControl id="password">
          <FormLabel>Sua senha</FormLabel>
          <InputGroup size="lg">
            <Input
              pr="4.5rem"
              type={!hiddenPassword ? 'text' : 'password'}
              placeholder="Sua senha"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
            <InputRightElement width="auto">
              <IconButton
                m="0px 5px 0 0"
                onClick={() => {
                  setHiddenPassword(!hiddenPassword)
                }}
                fontSize="22px"
                size="md"
                icon={
                  hiddenPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                }
                aria-label="Mostrar / Esconder senha "
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button
          mt={6}
          bg="blue.400"
          w={['100%', '100%', 'auto']}
          px="25px"
          color="white"
          _hover={{
            bg: 'blue.500'
          }}
          isLoading={loading}
          loadingText="Autenticando..."
          onClick={submit}
        >
          Entrar
        </Button>
      </Box>
      <Stack />
    </AuthTemplate>
  )
}

export default Login
