import React, { useState, useEffect } from 'react'
import DefaultTemplate from '../templates/DefaultTemplate'
import Breadcrumb from '../components/Breadcrumb'
import useWorkspace from '../hooks/use-workspace'
import AdminsTable from '../components/AdminsTable'
import Paginate from 'react-paginate'

import { Text } from '@chakra-ui/react'

const Admins = () => {
  const [reports, setReports] = useState([])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const { http: api } = useWorkspace()

  const getReports = async () => {
    try {
      const response = await api.get(`/user/admin/adminlogs?page=${page}`)
      setReports(response.data.data)
      setLastPage(response.data.meta.last_page)
    } catch (error) {
      console.log(error.message)
    }
  }

  const handlePageClick = e => {
    const selectedPage = e.selected
    setPage(selectedPage + 1)
  }

  useEffect(() => {
    getReports()
  }, [page])

  return (
    <>
      <DefaultTemplate page="admins">
        <Breadcrumb
          name="Admins"
          links={[{ name: 'Página inicial', url: '/' }]}
          current="Admins"
        />
        <Text fontSize="xx-large">Relatórios de alterações</Text>
        {reports.length > 1 ? (
          <>
            <AdminsTable reports={reports} />
            <Paginate
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={lastPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              previousLabel={'<'}
              nextLabel={'>'}
              onPageChange={handlePageClick}
            />
          </>
        ) : (
          <Text fontSize="xl">Não há relatórios</Text>
        )}
      </DefaultTemplate>
    </>
  )
}

export default Admins
