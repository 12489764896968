import React, { useState, useEffect } from 'react'
import { Box, Text, FormControl, FormHelperText } from '@chakra-ui/react'
import { Pie, Column } from '@ant-design/charts'
import useWorkspace from '../hooks/use-workspace'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'

const ReportGraphics = () => {
  const { http: api } = useWorkspace()
  const [subscribedUsers, setSubscribedUsers] = useState(0)
  const [unsubscribedUsers, setUnsubscribedUsers] = useState(0)
  const [courses, setCourses] = useState([])
  const [startDate, setStartDate] = useState(new Date('01/01/2019'))
  const [endDate, setEndDate] = useState(new Date())

  const handleChange = e => {
    if (e) {
      setStartDate(e[0])
      setEndDate(e[1])
    }
  }
  const getSubscriptionsData = async () => {
    try {
      const response = await api.get('reports/subscriptions', {
        params: {
          startDate,
          endDate
        }
      })

      console.log(response.data)
      setSubscribedUsers(response.data.totalSubscriptions[0].total)
      // Unsubscribed users = total - subscribed users
      setUnsubscribedUsers(
        response.data.users[0].total - response.data.totalSubscriptions[0].total
      )
    } catch (error) {
      console.log(error.message)
    }
  }

  const getCoursesData = async () => {
    try {
      const response = await api.get('reports/courses')

      setCourses(response.data.courses)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getSubscriptionsData()
  }, [startDate, endDate])

  useEffect(() => {
    getCoursesData()
  }, [])

  const subscriptionsData = [
    {
      type: 'Usuários não inscritos',
      value: subscribedUsers
    },
    { type: 'Usuários inscritos', value: unsubscribedUsers }
  ]
  const subscriptionsConfig = {
    appendPadding: 10,
    data: subscriptionsData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.6,
    label: {
      type: 'outer',
      content: '{name} {percentage}'
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }]
  }

  const coursesConfig = {
    data: courses,
    xField: 'name',
    yField: 'scholarships',
    xAxis: { label: { autoRotate: true } },
    meta: {
      scholarships: { alias: 'bolsas' }
    },
    slider: {
      start: 0.1,
      end: 0.3
    }
  }

  return (
    <Box bg="white" boxShadow="xs" p={6} borderRadius={8} mt={6} mb={10}>
      <Box w="100%">
        <Text mb={2} fontWeight={600} fontSize="2xl" textAlign="left">
          Inscrições
        </Text>
        <FormControl id="query">
          <DateRangePicker
            onChange={e => handleChange(e)}
            locale="pt-BR"
            format="dd/MM/y"
            value={[startDate, endDate]}
          />
          <FormHelperText>Selecione um período de busca</FormHelperText>
        </FormControl>
        <Pie {...subscriptionsConfig} />
      </Box>
      <Box w="100%">
        <Text fontWeight={600} mb={4} fontSize="2xl" textAlign="left">
          Bolsas ofertadas por curso
        </Text>
        <Column {...coursesConfig} />
      </Box>
    </Box>
  )
}

export default ReportGraphics
