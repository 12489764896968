import React, { useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge
} from '@chakra-ui/react'
import Paginate from 'react-paginate'

import DefaultTemplate from '../templates/DefaultTemplate'

import Breadcrumb from '../components/Breadcrumb'
import EmissionsFilter from '../components/EmissionsFilter'

export default function Emissions() {
  const [data, setData] = useState() // eslint-disable-line

  return (
    <DefaultTemplate page="emissions">
      <Breadcrumb
        name="Emissões"
        links={[{ name: 'Página inicial', url: '/' }]}
        current="Emissões"
      />
      <EmissionsFilter />
      <Table variant="simple" mt="6">
        <TableCaption>
          <Paginate
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageCount={25}
            forcePage={1}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            previousLabel={'Página anterior'}
            nextLabel={'Próxima página'}
            onPageChange={({ selected }) => {
              // setPage(() => Number(selected) + 1)
              console.log('Page', selected)
            }}
          />
        </TableCaption>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Candidato</Th>
            <Th>Instituição</Th>
            <Th>Curso</Th>
            <Th>Histórico de Notas</Th>
            <Th>Carta Matrícula</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.map(client => (
              <Tr key={`client-list-${client.id}`}>
                <Td isNumeric>{client.id}</Td>
                <Td>{client.name}</Td>
                <Td>{client.email}</Td>
                <Td>{client.document}</Td>
                <Td>
                  <Badge>Não vinculado</Badge>
                </Td>
                <Td>
                  <Badge>Não vinculado</Badge>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </DefaultTemplate>
  )
}
