import React from 'react'
import { Link } from 'react-router-dom'
import { BsChevronCompactRight } from 'react-icons/bs'

import {
  Box,
  Heading,
  Breadcrumb as Container,
  BreadcrumbItem,
  BreadcrumbLink
} from '@chakra-ui/react'

export default function Breadcrumb({ name, links, current }) {
  return (
    <Box p="25px" bg="gray.100" borderRadius={15}>
      <Heading>{name}</Heading>
      <Container
        mt={3}
        spacing="8px"
        separator={<BsChevronCompactRight color="gray.500" />}
      >
        {links.map((link, idx) => (
          <BreadcrumbItem key={idx}>
            <Link to={link.url}>
              <BreadcrumbLink color="blue.300">{link.name}</BreadcrumbLink>
            </Link>
          </BreadcrumbItem>
        ))}
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">{current}</BreadcrumbLink>
        </BreadcrumbItem>
      </Container>
    </Box>
  )
}
