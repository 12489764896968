import styled from 'styled-components'

export const Wrapper = styled.div`
  .react-daterange-picker {
    padding: 10px;
    border-radius: 5px;
    background: #edf2f7;

    .react-daterange-picker__wrapper {
      border: none;
    }
  }

  ul {
    list-style: none;
  }

  .pagination {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 15px;

    margin: 0;
    padding: 0;

    margin-top: 30px;

    li {
      a {
        padding: 10px;
        background: rgba(0, 0, 0, 0.1);
        color: #000;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }

      &.active {
        a {
          background-color: #4299e1;
          color: #fff;
        }
      }
    }
  }

  img {
    height: 50px;
    width: 126px;
  }
`
