import React, { useState } from 'react'

import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  Box
} from '@chakra-ui/react'

import { Divider } from '@/components'

import useWorkspace from '../../hooks/use-workspace'

const ResendPassword = ({ user }) => {
  const [isPasswordSent, setIsPasswordSent] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const { http: api } = useWorkspace()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleResendPassword = async () => {
    setLoading(true)
    try {
      const response = await api.put(`user/admin/user-password/${user.user_id}`)

      console.log(response)
      setNewPassword(response.data.newPassword)
      setIsPasswordSent(true)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
      setLoading(false)
    }
  }

  const handleClose = () => {
    setIsPasswordSent(false)
    setNewPassword('')

    onClose()
  }

  return (
    <>
      <Button bg="transparent" fontWeight="400" w="100%" onClick={onOpen}>
        Reenviar senha
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reenviar senha</ModalHeader>
          <ModalBody>
            {isPasswordSent ? (
              <>
                <Text>
                  A senha enviada para o email <b>{user.email}</b> foi:
                </Text>

                <Divider size={2} orientation="horizontal" />

                <Text textAlign="center" fontWeight="bold">
                  {newPassword}
                </Text>
              </>
            ) : (
              <p>Deseja reenviar senha para {user.first_name}?</p>
            )}
          </ModalBody>

          <ModalFooter>
            <Flex width="100%" justifyContent="space-between">
              <Box>
                <Button colorScheme="gray" onClick={handleClose}>
                  Fechar
                </Button>
              </Box>

              <Box>
                {!isPasswordSent && (
                  <Button
                    isLoading={loading}
                    disabled={loading}
                    onClick={handleResendPassword}
                    colorScheme="green"
                  >
                    Gerar e enviar senha
                  </Button>
                )}
              </Box>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ResendPassword
