import React from 'react'
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Heading
} from '@chakra-ui/react'
import { createGlobalStyle } from 'styled-components'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'

const GlobaStyle = createGlobalStyle`
  .react-daterange-picker {
    padding: 10px;
    border-radius: 5px;
    background: #EDF2F7;

    .react-daterange-picker__wrapper {
      border: none;
    }
  }

  ul {
    list-style: none
  }

  .pagination {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 15px;

    margin: 0;
    padding: 0;

    margin-top: 30px;

    li {
      a {
        padding: 10px;
        background: rgba(0, 0, 0, 0.1);
        color: #000;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        transition: all ease .3s;

        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }

      &.active {
        a {
          background-color: #4299e1;
          color: #fff;
        }
      }
    }
  }
`

function EmissionsFilter() {
  return (
    <Box bg="white" boxShadow="xs" p={6} borderRadius={8} mt={6} mb={10}>
      <GlobaStyle />
      <Heading size="lg" mb={6}>
        Filtro de busca
      </Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap="20px">
        <FormControl id="person">
          <FormLabel>IES</FormLabel>
          <Select size="lg" variant="filled">
            <option>Todas</option>
          </Select>
        </FormControl>
        <FormControl id="types">
          <FormLabel>Emissão</FormLabel>
          <Select size="lg" variant="filled">
            <option>Todas</option>
            <option>Carta Matricula</option>
            <option>Histórico de Notas</option>
            <option>Não emitido</option>
          </Select>
        </FormControl>
        <FormControl id="query">
          <FormLabel>Período</FormLabel>
          <DateRangePicker locale="pt-BR" format="dd/MM/y" />
          <FormHelperText>Selecione um período de busca</FormHelperText>
        </FormControl>
      </Grid>
    </Box>
  )
}

export default EmissionsFilter
