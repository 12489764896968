import React, { useState } from 'react'
import useWorkspace from '../../hooks/use-workspace'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
  ModalCloseButton,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Divider,
  FormErrorMessage,
  Flex,
  useToast
} from '@chakra-ui/react'

import InputMask from 'react-input-mask'

import { dateFormatter, dateToIso } from '@/utils/date.util'
import { ValueOf } from '@/protocols/CommonProtocol'
import { IUserData as IUser } from '@/protocols/User.protocol'
import useValidation from '@/hooks/useValidation'

type IUserData = {
  firstName: string
  lastName: string
  email: string
  cpf: string
  phone: string
  birthDate: string
}

type EditUserProps = {
  user: IUser
  onUpdate: () => Promise<void>
}

const EditUser: React.FC<EditUserProps> = props => {
  const { user, onUpdate } = props

  const [loading, setLoading] = useState(false)
  const [editUserData, setEditUserData] = useState<Partial<IUserData>>({})

  const {
    validation,
    triggerValidation,
    clearValidation,
    clearAllValidations
  } = useValidation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { http: api } = useWorkspace() as any

  const handleUserDataChange = (
    type: keyof IUserData,
    value: ValueOf<IUserData>
  ) => {
    clearValidation(type)

    setEditUserData(currentState => ({
      ...currentState,
      [type]: value
    }))
  }

  const handleClose = () => {
    clearAllValidations()
    setEditUserData({})

    onClose()
  }

  const UpdateUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)

    try {
      await api.put(`user/admin/update-user/${user.user_id}`, {
        ...editUserData,
        ...(editUserData.birthDate && {
          birthDate: dateToIso(editUserData.birthDate)
        })
      })
      await onUpdate()

      handleClose()
      toast({
        title: 'Dados atualizados',
        description: 'Informações do usuário foram atualizadas com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top-right'
      })
    } catch (error: any) {
      triggerValidation(error)

      toast({
        title: 'Houve um erro ao realizar esta requisição',
        description: `${error?.response?.data?.message || 'Revise os campos'}`,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right'
      })
    }

    setLoading(false)
  }

  return (
    <>
      <Button bg="transparent" fontWeight="400" w="100%" onClick={onOpen}>
        Editar informações
      </Button>
      <Modal size="2xl" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar informações</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={UpdateUser}>
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem>
                  <FormControl isInvalid={validation.firstName} id="firstName">
                    <FormLabel>Nome</FormLabel>
                    <Input
                      defaultValue={user.first_name}
                      name="firstName"
                      onChange={({ target }) =>
                        handleUserDataChange('firstName', target.value)
                      }
                      placeholder="João"
                    />
                    {validation?.firstName && (
                      <FormErrorMessage>
                        {validation.firstName}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={validation.lastName} id="lastName">
                    <FormLabel>Sobrenome</FormLabel>
                    <Input
                      defaultValue={user.last_name}
                      onChange={({ target }) =>
                        handleUserDataChange('lastName', target.value)
                      }
                      placeholder="Silva"
                    />
                    {validation?.lastName && (
                      <FormErrorMessage>{validation.lastName}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={validation.email} id="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                      defaultValue={user.email}
                      onChange={({ target }) =>
                        handleUserDataChange('email', target.value)
                      }
                      placeholder="joaosilva@mail.com"
                    />
                    {validation?.email && (
                      <FormErrorMessage>{validation.email}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={validation.cpf} id="cpf">
                    <FormLabel>CPF</FormLabel>
                    <Input
                      defaultValue={user.cpf}
                      as={InputMask}
                      mask="999.999.999-99"
                      onChange={({ target }) =>
                        handleUserDataChange('cpf', target.value)
                      }
                      placeholder="123.456.789-10"
                    />
                    {validation?.cpf && (
                      <FormErrorMessage>{validation.cpf}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={validation.birthDate} id="birthDate">
                    <FormLabel>Data de nascimento</FormLabel>
                    <Input
                      defaultValue={dateFormatter(user.birth_date)}
                      as={InputMask}
                      mask="99/99/9999"
                      onChange={({ target }) =>
                        handleUserDataChange('birthDate', target.value)
                      }
                      placeholder="dd/mm/aaaa"
                    />
                    {validation?.birthDate && (
                      <FormErrorMessage>
                        {validation.birthDate}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={validation.phone} id="phone">
                    <FormLabel>Celular</FormLabel>
                    {console.log(':>::', validation)}
                    <Input
                      defaultValue={user.phone}
                      as={InputMask}
                      mask="(99) 99999-9999"
                      onChange={({ target }) =>
                        handleUserDataChange('phone', target.value)
                      }
                      placeholder="(21) 98877-6655"
                    />
                    {validation?.phone && (
                      <FormErrorMessage>{validation.phone}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Grid>
              <Divider mt={4} mb={4} />
              <Flex width="100%" justifyContent="flex-end">
                <Button
                  type="submit"
                  width="56"
                  colorScheme="green"
                  isLoading={loading}
                  isDisabled={loading}
                >
                  Salvar
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditUser
