import React from 'react'

import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'
import { dateFormatter } from '../utils/date.util'
import ReportsChanges from './Modals/ReportsChanges'
const AdminsTable = ({ reports }) => {
  return (
    <Table variant="simple" size="sm" mt="6">
      <Thead>
        <Tr>
          <Th>Admin</Th>
          <Th>Usuário</Th>
          <Th>Sobrenome</Th>
          <Th>Data da alteração</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {reports.map(report => (
          <Tr key={report.id}>
            <Td>{report.admin.name}</Td>
            <Td>{report.user.profile.first_name}</Td>
            <Td>{report.user.profile.last_name}</Td>
            <Td>{dateFormatter(report.created_at)}</Td>
            <Td>
              <ReportsChanges changes={report.changes} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default AdminsTable
