import React, { useState } from 'react'

import InputMask from 'react-input-mask'
import {
  Flex,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow
} from '@chakra-ui/react'
import Paginate from 'react-paginate'
import { BiDotsVerticalRounded } from 'react-icons/bi'

import useWorkspace from '@/hooks/use-workspace'
import useDidMount from '@/hooks/useDidMount'
import ResendPassword from '@/components/Modals/ResendPassword'
import ResendInstructions from '@/components/Modals/ResendInstructions'
import EditUser from '@/components/Modals/EditUser'
import UserSubsCriptions from '@/components/Modals/UserSubscriptions'

import DefaultTemplate from '@/templates/DefaultTemplate'
import Breadcrumb from '@/components/Breadcrumb'

import * as S from '@/styles/components/UsersTable'
import { IMetaResponse, ValueOf } from '@/protocols/CommonProtocol'
import { IUserData } from '@/protocols/User.protocol'
import { dateFormatter } from '@/utils/date.util'
import { Loading } from '@/components'

type IFilterData = {
  firstName: string
  lastName: string
  email: string
  cpf: string
  phone: string
  birthDate: string
  page: number
}

const Users = () => {
  const { http: api } = useWorkspace() as any

  const [loading, setLoading] = useState<boolean>(true)
  const [filterData, setFilterData] = useState<IFilterData>({
    firstName: '',
    lastName: '',
    email: '',
    cpf: '',
    phone: '',
    birthDate: '',
    page: 1
  })
  const [users, setUsers] = useState<{
    data: IUserData[]
    meta?: IMetaResponse
  }>({
    data: []
  })
  const handleSearch = async (newFilterData?: Partial<IFilterData>) => {
    setLoading(true)

    try {
      const { data } = await api.get('/user/admin/search-user', {
        params: {
          ...filterData,
          ...newFilterData
        }
      })
      setUsers(data)
    } catch (error: any) {
      console.log(error?.message)
    }

    setLoading(false)
  }

  const handleFilterDataChange = (
    type: keyof IFilterData,
    value: ValueOf<IFilterData>
  ) => {
    setFilterData(currentState => ({
      ...currentState,
      [type]: value
    }))
  }

  const handlePageClick = async (page: number) => {
    const selectedPage = page + 1

    handleFilterDataChange('page', selectedPage)

    await handleSearch({ page: selectedPage })
  }

  useDidMount(() => {
    handleSearch()
  })
  return (
    <DefaultTemplate page="users">
      <Breadcrumb
        name="Usuários"
        links={[{ name: 'Página inicial', url: '/' }]}
        current="Usuários"
      />

      <Loading loading={loading}>
        <S.Wrapper>
          <Flex
            flexDir="column"
            bg="white"
            boxShadow="xs"
            p={6}
            borderRadius={8}
            mt={6}
            mb={10}
          >
            <Text fontSize="2xl" mb={2}>
              Busca
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={3}>
              <GridItem>
                <FormControl id="firstName">
                  <FormLabel>Nome</FormLabel>
                  <Input
                    variant="outline"
                    onChange={({ target }) =>
                      handleFilterDataChange('firstName', target.value)
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="lastName">
                  <FormLabel>Sobrenome</FormLabel>
                  <Input
                    variant="outline"
                    onChange={({ target }) =>
                      handleFilterDataChange('lastName', target.value)
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    variant="outline"
                    onChange={({ target }) =>
                      handleFilterDataChange('email', target.value)
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="cpf">
                  <FormLabel>CPF</FormLabel>
                  <Input
                    as={InputMask}
                    mask="999.999.999-99"
                    variant="outline"
                    onChange={({ target }) =>
                      handleFilterDataChange('cpf', target.value)
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="birthDate">
                  <FormLabel>Data de nascimento</FormLabel>
                  <Input
                    as={InputMask}
                    mask="99/99/9999"
                    variant="outline"
                    onChange={({ target }) =>
                      handleFilterDataChange('birthDate', target.value)
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="phone">
                  <FormLabel>Celular</FormLabel>
                  <Input
                    as={InputMask}
                    mask="(99) 99999-9999"
                    variant="outline"
                    onChange={({ target }) =>
                      handleFilterDataChange('phone', target.value)
                    }
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <Button
              _hover={{ bg: '#4299E1', color: '#fff' }}
              width={40}
              mt={6}
              alignSelf="flex-end"
              onClick={() => handleSearch()}
              isLoading={loading}
              isDisabled={loading}
            >
              Buscar
            </Button>
          </Flex>
          {users.data.length ? (
            <>
              <Table variant="simple" size="sm" mt="6">
                <Thead>
                  <Tr>
                    <Th>Opções</Th>
                    <Th>Nome</Th>
                    <Th>Sobrenome</Th>
                    <Th>Email</Th>
                    <Th>CPF</Th>
                    <Th>Data de nascimento</Th>
                    <Th>Telefone</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.data.map(user => (
                    <Tr key={user.id}>
                      <Td>
                        <Popover placement="top-start">
                          <PopoverTrigger>
                            <Button
                              bg="transparent"
                              padding={0}
                              _hover={{ bg: 'transparent' }}
                            >
                              <BiDotsVerticalRounded />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent width="56">
                            <PopoverArrow />

                            <PopoverBody>
                              <ResendPassword user={user} />
                              <ResendInstructions user={user} />

                              <EditUser user={user} onUpdate={handleSearch} />

                              <UserSubsCriptions
                                user={user}
                                onUpdate={handleSearch}
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Td>
                      <Td>{user.first_name}</Td>
                      <Td>{user.last_name}</Td>
                      <Td>{user.email}</Td>
                      <Td>{user.cpf}</Td>
                      <Td>{dateFormatter(user.birth_date)}</Td>
                      <Td>{user.phone}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Paginate
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageCount={users?.meta?.last_page || 0}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={({ selected }) => handlePageClick(+selected)}
              />
            </>
          ) : (
            <Text>Nenhum usuário encontrado com estes parâmetros de busca</Text>
          )}
        </S.Wrapper>
      </Loading>
    </DefaultTemplate>
  )
}

export default Users
