import React from 'react'

import { Grid, Heading, Text, Box } from '@chakra-ui/react'

function AuthTemplate({ children, subtitle, message }) {
  return (
    <main>
      <Grid
        w="100%"
        h="100vh"
        p="30px"
        templateColumns={['100%', '100%', '580px auto']}
        gap={8}
      >
        <Box
          d="flex"
          flexDir="column"
          justifyContent="space-between"
          alignItems="stretch"
          w="100%"
          h="100%"
          py={['0', '0', '30px']}
        >
          {children}
        </Box>
        <Box
          d={['none', 'none', 'flex']}
          pos="relative"
          p="60px"
          w="100%"
          h="100%"
          flexDir="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          bg="blue.100"
          borderRadius="40px"
        >
          <Heading as="h2" maxW="400px" fontWeight="semibold" size="xl">
            {subtitle || ''}
          </Heading>
          <Text as="h3" maxW="400px" fontWeight="light" size="xl">
            {message}
          </Text>
        </Box>
      </Grid>
    </main>
  )
}

export default AuthTemplate
